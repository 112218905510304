<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="fundraising"
    itemscope
    itemtype="http://schema.org/WebPage"
  >


  <div class="masonry-wrapper work">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Our Work</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <section class="text-center quarternary-back">
          <!-- Project Navigation Tile -->
          <Section class="image-text colour2 our-work" wrap>
            <div class="col-md-12 text-center">
                <div class="title-row">
                  <h2 class="section-title intro__text">Ignite facilities supports a range of businesses across Staffordshire & Cheshire</h2>
                </div>
                <div class="sub-title-row">
                  <p class="section-title">As a dedicated facilities management company, Ignite Facilities has provided its services to a variety of businesses across Staffordshire and Cheshire. You can see the various businesses below.</p>
                </div>
              </div>
          </Section>

          <CaseStudies />

          <div class="image-mosaic mt-32">
            <div
              class="card card-tall card-wide"
              style="background-image: url('/img/ignite/customers/Cazoo_2.jpg')"
            >
              <img class="card-logo-watermark top-left" src="@/img/testimonials/cazoo-grid.jpg">
            </div>

            <div
              class="card card-tall"
              style="background-image: url('/img/ignite/customers/BBC_Television_Centre.jpg')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/BBC.png">
            </div>

            <div
              class="card card-tall"
              style="background-image: url('/img/ignite/customers/Warwick_uni.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/warwick.jpg">
            </div>                      

            <div
              class="card card-tall card-wide"
              style="background-image: url('/img/ignite/customers/Milehouse.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/milehouse.jpg">
            </div>
            
            <div
              class="card"
              style="background-image: url('/img/ignite/customers/DCS_Reddich.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/dcs.png">
            </div>

            <div
              class="card"
              style="background-image: url('/img/ignite/customers/HMP_Leicester.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/HMPPS-SMALL-BLACK.jpg">
            </div>

            <div
              class="card"
              style="background-image: url('/img/ignite/customers/Marks_and_Spencers_lemmington_spa.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/marksandspencer.jpg">
            </div>

            <div
              class="card"
              style="background-image: url('/img/ignite/customers/Aura_brand_solutions_2.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/Aura.jpg">
            </div>

            <div
              class="card card-tall"
              style="background-image: url('/img/ignite/customers/Rush_UK_Kings_Norton.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/Rusk_UK.jpg">
            </div>

            <div
              class="card card-tall"
              style="background-image: url('/img/ignite/customers/Biddulph_Town_Hall.jpg')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/biddulph.jpg">
            </div>

            <div
              class="card card-tall card-wide"
              style="background-image: url('/img/ignite/customers/Holy_trinity_church_denford.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/st-james.jpg">
            </div>

            <div
              class="card card-tall"
              style="background-image: url('/img/ignite/customers/Funko_uk_1.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/funko.jpg">
            </div>

            <div
              class="card card-tall"
              style="background-image: url('/img/ignite/customers/MOD_Donnigton.JPG')"
            >
              <img class="card-logo-watermark top-right" src="@/img/testimonials/MOD.jpg">
            </div>

            <div
              class="card"
              style="background-image: url('/img/ignite/customers/Edinburgh_playhouse_theatre.JPG')"
            >
              <img class="card-logo-watermark top-left small6" src="@/img/testimonials/ATG-cust.jpg">
            </div>

            <div
              class="card"
              style="background-image: url('/img/ignite/customers/Roy_Caslte_Hospice.JPG')"
            >
              <img class="card-logo-watermark bottom-left small6" src="@/img/testimonials/Roy-castle.jpg">
            </div>

            <div
              class="card card-tall"
              style="background-image: url('/img/ignite/customers/Churchill_China.jpg')"
            >
              <img class="card-logo-watermark top-left small6" src="@/img/testimonials/churchill-cust.jpg">
            </div>

            <div
              class="card card-tall"
              style="background-image: url('/img/ignite/customers/Sunderland_empire_theatre_1.JPG')"
            >
              <img class="card-logo-watermark bottom-left small6" src="@/img/testimonials/ATG-cust.jpg">
            </div>





            
            <div
              class="card"
              style="background-image: url('/img/gallery/19.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/23.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/24.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/5.jpg')"
            ></div>
            <div
              class="card card-wide"
              style="background-image: url('/img/gallery/6.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/3.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/13.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/25.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/2.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/4.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/21.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/17.jpg')"
            ></div>
            <div
              class="card"
              style="background-image: url('/img/gallery/20.jpg')"
            ></div>


          </div>          
        </section>

        <!-- Benefits of Ignite Facilities section -->
          <div class="masonry-wrapper our-work-masonry">
            <div class="masonry home-services benefits"> 
              <div class="overlay masonry-item">
                <div class="masonry-title mt-32">
                  <h2>
                    Benefits
                  </h2>
                  <div class="services-ul services-grid">
                    <div>                    
                      <p> 
                        <img class="tick" src="/img/tick.png">
                        24 Hour Call Out
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        Emergency Services
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        Tailored Packages
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        Out of Hours Call Out Number
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        National Coverage
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        Planned Maintenance
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        Reactive Maintenance
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        Full Electrical and Heating Installations
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        Risk Assessment
                      </p>
                    </div>
                    <div>                    
                      <p>
                        <img class="tick" src="/img/tick.png">
                        Free Site Surveys
                      </p>
                    </div>
                  </div>
                  <div>
                    <router-link to="contact">
                      <button class="client-button-orange-solid">
                        Contact Us
                      </button>
                    </router-link>                  
                  </div>                
                </div>
              </div>            
            </div>
          </div>
        
      </div>
    </main>
  </body>
</template>

<script src='https://kit.fontawesome.com/a076d05399.js'></script>
<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Grants",
  metaInfo: {
    title: 'Our Work Services - Expert Heating, Plumbing & Electrical Solutions',
    titleTemplate: null,
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Our Work Services - Expert Heating, Plumbing & Electrical Solutions | Ignite Facilities'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Explore our work services for expert heating, plumbing, and electrical solutions. Trust Ignite Facilities for your building needs.'
      }
    ]
  },
  components: {
    CaseStudies
  },
  data() {
    return {
      hiltonImg: "/img/gallery/12.jpg",
      waterworldImg: "/img/gallery/17.jpg",
      churchillImg: "/img/gallery/10.jpg",
      westernImg: "/img/gallery/19.jpg",
      // holdingImg: "/images/holding_proj_img.jpg",
    };
  },
};
</script>

<style lang="scss">
.grants {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
